<template>
  <div class="wrapper wrapper-full-page section content">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-8">
          <h1 class="title text-danger">404 Page Not Found</h1>
          <h2 class="title">Oops! It seems that this page does not exist.</h2>
          <a href="/" class="btn btn-primary">Back to homepage</a>
        </div>
        <div class="col-md-4">
          <img src="/img/page-not-found-4.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.wrapper-full-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
