<template>
  <div class="divider"></div>
</template>
<script>
export default {
  name: "divider"
};
</script>
<style lang="scss" scoped>
.divider {
  width: 100vw !important;
  height: 1px;
  background-color: rgba($color: #fff, $alpha: 0.2);
  margin: 15px;
}
</style>
