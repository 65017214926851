<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <span class="nav-link"><p>Overwiew</p></span>
        <sidebar-link
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />
        <span class="nav-link"><p>Brawks</p></span>
        <sidebar-link
          to="/lagrossegame"
          :name="$t('lagrossegame')"
          icon="tim-icons icon-bullet-list-67"
        />
        <span class="nav-link"><p>Huskow</p></span>
        <sidebar-link
          to="/huskow"
          :name="$t('huskowlebot')"
          icon="tim-icons icon-bullet-list-67"
        />
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
